import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Tab, Nav, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

export function RoundedButton({ classes, children }) {
    return <>

        <Button className={"" + classes}>{children}</Button>
    </>
}

export function ToggleButton({ events, classes, children }) {
    return <>
        <button className={`btn btn-sm font-s border-radius-3 ${classes}`} {...events}>{children}</button>
    </>
    // return <>
    //     <div className="bg-white d-flex-inline">
    //         {/* <div className="bg-blue font-white font-s py-5px px-10px">INR</div>
    //         <div className="font-s py-5px px-10px">FOREX</div> */}
    //         <button className="btn btn-sm bg-blue font-white font-s border-radius-3">INR</button>
    //         <button className="btn btn-sm bg-white font-s border-radius-3">USD</button>
    //     </div>
    // </>
}
export function Footer() {
    const history = useHistory();
    const [page, setPage] = useState("");
    function getLastWordFromURL() {
        // Get the current URL's path
        const path = window.location.pathname;

        // Split the path by '/'
        const pathSegments = path.split('/');

        // Get the last segment which should be the last word after the last '/'
        const lastSegment = pathSegments[pathSegments.length - 1];

        // Return the last word
        return lastSegment;
    }

    // useEffect(() => {
    //     setPage(getLastWordFromURL());
    //     console.log(page)
    // }, []);

    return <>
        <div className="footer">
            <Tab.Container id="tabs-example" defaultActiveKey={getLastWordFromURL()}>
                <Nav className="d-flex justify-content-between footer-tabs" variant="tabs">
                <Nav.Item>
                        <Nav.Link eventKey="portfolio" onClick={() => { history.push("/portfolio"); }}><div className="mb-2"><img src="images/portfolio.svg" alt="portfolio" /></div>Portfolio</Nav.Link>
                </Nav.Item>


                    <Nav.Item>
                        <Nav.Link eventKey="watchlist" onClick={() => { history.push("/watchlist"); }}><div className="mb-2"><img src="images/watchlist.svg" alt="watchlist" /></div>Watchlist</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="market" onClick={() => { history.push("/market"); }}><div className="mb-2"><img src="images/market.svg" alt="market" /></div>Market</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link eventKey="order" onClick={() => { history.push("/order"); }}><div className="mb-2"><img src="images/order.svg" alt="order" /></div>Order</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="account" onClick={() => { history.push("/account"); }}><div className="mb-2"><img src="images/account.svg" alt="account" /></div>Account</Nav.Link>
                    </Nav.Item>
                </Nav>
                {/* <Tab.Content>
                    <Tab.Pane eventKey="market">
                        <div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="watchlist">
                        <div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="portfolio">
                        <div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="order">
                        <div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="account">
                        <div>
                        </div>
                    </Tab.Pane>
                </Tab.Content> */}
            </Tab.Container>
        </div>
    </>
}

export function IndexBox() {
    const indexBoxState = useSelector((state) => state.indexBox);
    const socketState = useSelector((state) => state.socket);
    const [indexBox, setIndexBox] = useState({ "index1": {}, "index2": {} });
    useEffect(() => {
        setIndexBox(indexBoxState)
        socketState.priceSocket.emit("tokenData", indexBoxState.index1.token);
        socketState.priceSocket.emit("tokenData", indexBoxState.index2.token)
        // when price come
        socketState.priceSocket.on("room" + indexBoxState.index1.token, (instrument) => {
            setIndexBox(prevState => ({
                ...prevState,
                index1: instrument
            }));
        })
        socketState.priceSocket.on("room" + indexBoxState.index2.token, (instrument) => {
            setIndexBox(prevState => ({
                ...prevState,
                index2: instrument
            }));
        })

        return () => {
            // Unsubscribe from socket events
            socketState.priceSocket.off("tokenData", indexBoxState.index1.token);
            socketState.priceSocket.off("tokenData", indexBoxState.index2.token);

        };
    }, [indexBoxState]);

    return <>
        <div className="d-flex gap-3">
            <Card className="border-0 p-0 mb-3 w-50">
                <Card.Body className="d-flex align-items-center p-3">
                    <img src="images/bitcoin.svg" alt="bitcoin" />
                    <div className="ms-3">
                        <span className="d-block font-m mb-1">{indexBox.index1.symbol}</span>
                        <small className={`${(indexBox.index1.percentage_change > 0) ? "font-green" : "font-red"} font-s`}>{indexBox.index1.percentage_change}% <i class={`fa-solid font-m ${(indexBox.index1.percentage_change > 0) ? "fa-caret-up font-green" : "fa-caret-down font-red"}`} style={{ fontWeight: "900" }}></i></small>
                    </div>
                </Card.Body>
            </Card>
            <Card className="border-0 p-0 mb-3 w-50">
                <Card.Body className="d-flex align-items-center p-3">
                    <img src="images/usdt-icon.svg" alt="usdt-icon" />
                    <div className="ms-3">
                        <span className="d-block font-m mb-1">{indexBox.index2.symbol}</span>
                        <small className={`${(indexBox.index2.percentage_change > 0) ? "font-green" : "font-red"} font-s`}>{indexBox.index2.percentage_change}% <i class={`fa-solid font-m ${(indexBox.index2.percentage_change > 0) ? "fa-caret-up font-green" : "fa-caret-down font-red"}`} style={{ fontWeight: "900" }}></i></small>
                    </div>
                </Card.Body>
            </Card>
        </div>
    </>
}



export function EmptyPageImage() {
    return (
        <div className="d-flex justify-content-center flex-column align-items-center">
            <img src="images/orderexecuted-image.svg" alt="orderexecuted-image" />
            <div className="font-l text-center font-dark-grey fw-normal mt-4">No orders found</div>
        </div>
    )
}