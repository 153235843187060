import React, { useState, useEffect } from 'react';
import { TopGainer, TopLooser } from '../../../components/market/smallComponents.jsx';
import { Footer } from '../../../components/common/common.jsx';
import { Card, Tab, Tabs, Nav, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { fetchGainers, fetchLoosers } from "../../../store/market/action";
import { BottomSheetWatchlist } from '../../private/watchlist/BottomSheetWatchlist.jsx';
import { changeStatusSheetWatchlist, updateInstrumentSheetWatchlist } from '../../../store/bottomSheetWatchlist/action.jsx';
import { IndexBox } from '../../../components/common/common.jsx';
import { useHistory } from 'react-router-dom';

export default function Market() {
    const history = useHistory();
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const marketState = useSelector((state) => state.market);
    const socketState = useSelector((state) => state.socket);
    const profileState = useSelector((state) => state.profile);

    const [loosers, setLoosers] = useState({
        "NFO": {},
        "MCX": {},
        "CDS": {},
        "CRYPTO": {},
        "FX": {},
        "US": {}
    });
    const [gainers, setGainers] = useState({
        "NFO": {},
        "MCX": {},
        "CDS": {},
        "CRYPTO": {},
        "FX": {},
        "US": {}
    });
    const markets = ["NFO", "MCX", "CRYPTO", "FX", "US"]
    useEffect(() => {
        dispatch(fetchGainers(loginState.jwttoken))
        dispatch(fetchLoosers(loginState.jwttoken))
    }, [])


    useEffect(() => {
        let tempG = {
            "NFO": {},
            "MCX": {},
            "CDS": {},
            "CRYPTO": {},
            "FX": {},
            "US": {}
        };
        let tempL = {
            "NFO": {},
            "MCX": {},
            "CDS": {},
            "CRYPTO": {},
            "FX": {},
            "US": {}
        };
        markets.forEach((exchange) => {
            // set loosers
            marketState.loosers[exchange].forEach((instrument) => {
                // store in local watchlist for fast updating structure
                tempL[exchange][instrument.token] = instrument
                // subscribe price
                socketState.priceSocket.emit("tokenData", instrument.token)
                // when price come
                socketState.priceSocket.on("room" + instrument.token, (instrument) => {
                    // let watchlist = watchlistRef.current
                    // let temp = { ...watchlist[instrument.exchange] };
                    // temp[instrument.token] = instrument;
                    // console.log(watchlist[instrument.exchange])
                    // setWatchlist({ ...watchlist, [instrument.exchange]: { ...watchlist[instrument.exchange], [instrument.token]: instrument } })
                    setLoosers(prevState => ({
                        ...prevState,
                        [instrument.exchange]: {
                            ...prevState[instrument.exchange],
                            [instrument.token]: instrument
                        }
                    }));
                })
            })

            marketState.gainers[exchange].forEach((instrument) => {
                // store in local watchlist for fast updating structure
                tempG[exchange][instrument.token] = instrument
                // subscribe price
                socketState.priceSocket.emit("tokenData", instrument.token)
                // when price come
                socketState.priceSocket.on("room" + instrument.token, (instrument) => {
                    // let watchlist = watchlistRef.current
                    // let temp = { ...watchlist[instrument.exchange] };
                    // temp[instrument.token] = instrument;
                    // console.log(watchlist[instrument.exchange])
                    // setWatchlist({ ...watchlist, [instrument.exchange]: { ...watchlist[instrument.exchange], [instrument.token]: instrument } })
                    setGainers(prevState => ({
                        ...prevState,
                        [instrument.exchange]: {
                            ...prevState[instrument.exchange],
                            [instrument.token]: instrument
                        }
                    }));
                })
            })
        });
        setLoosers(tempL);
        setGainers(tempG);

        return () => {
            // Unsubscribe from socket events
            markets.forEach((exchange) => {
                marketState.loosers[exchange].forEach((instrument) => {
                    socketState.priceSocket.off("room" + instrument.token);
                });
                marketState.gainers[exchange].forEach((instrument) => {
                    socketState.priceSocket.off("room" + instrument.token);
                });
            });
        };
    }, [marketState])


    function sheetChangingHandler(instrument) {
        dispatch(updateInstrumentSheetWatchlist(instrument));
        dispatch(changeStatusSheetWatchlist(true));
    }


    
    function redirectToChatWhatsapp() {
        let message = encodeURIComponent(`${profileState.username} need support`);
        window.location.href = `https://wa.me/919512441354?text=${message}`;
    }

    return <>
        <div className="screen">
            <BottomSheetWatchlist />
            <div className="main-content bg-light">
                {/* section first */}
                <div>
                    <Card className="border-0 bg-transparent p-0 mb-3">
                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                            <div>
                                <span className="icon-red me-2">{(profileState.firstname) ? (profileState.firstname[0] + profileState.lastname[0]) : ""}</span>
                                <span>Hi {(profileState.firstname) ? profileState.firstname : ""}</span>
                            </div>
                            <ul className="icons-list">
                                <li><img src="images/search-icon.svg" alt="search-icon" onClick={() => { history.push("/search-bar") }} /></li>
                                {/* <li><img src="images/notification.svg" alt="notification-bell" /><span className="notification-count">12</span></li> */}
                            </ul>
                        </Card.Body>
                    </Card>
                </div>
                {/* section second index */}
                {/* <IndexBox /> */}
                <div className='mb-3 transaction-buttons'>
                    <div className='d-flex justify-content-between'>
                        <button className='col deposit-btn btn btn-primary' onClick={() => { history.push("/account-deposite") }}>Deposit</button>
                        <div className='col-1'></div>
                        <button className='col withdraw-btn btn btn-primary' onClick={() => { history.push("/account-withdraw") }}>Withdraw</button>
                    </div>
                </div>
                <div className='mb-3 transaction-buttons'>
                        <button className='col deposit-btn btn btn-outline-success btn-block w-100' onClick={() => { redirectToChatWhatsapp() }}>Whatsapp Support</button>
                </div>
                {/* <div className="d-flex gap-3">
                    <Card className="border-0 p-0 mb-3 w-50">
                        <Card.Body className="d-flex align-items-center p-3">
                            <img src="images/bitcoin.svg" alt="bitcoin" />
                            <div className="ms-3">
                                <span className="d-block font-m mb-1">Crypto/BTC</span>
                                <small className="font-red font-s">-132,5%<img className="ms-2" src="images/red-arrow.svg" alt="red-arrow" /></small>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 p-0 mb-3 w-50">
                        <Card.Body className="d-flex align-items-center p-3">
                            <img src="images/usdt-icon.svg" alt="usdt-icon" />
                            <div className="ms-3">
                                <span className="d-block font-m mb-1">Crypto/USDT</span>
                                <small className="font-green font-s">-132,5%<img className="ms-2" src="images/green-arrow.svg" alt="green-arrow" /></small>
                            </div>
                        </Card.Body>
                    </Card>
                </div> */}
                {/* section third */}
                <div>

                    <Tab.Container id="tabs-example" defaultActiveKey="NFO">
                        {/* market tabs */}
                        <Nav className="d-flex justify-content-between custom-tab" variant="tabs">
                            {markets.map((value, index) => {
                                return <>
                                    <Nav.Item key={index}>
                                        <Nav.Link eventKey={value}>{value.toUpperCase()}</Nav.Link>
                                    </Nav.Item>
                                </>
                            })}
                        </Nav>
                        <Tab.Content>
                            {/* single single tab for every market */}
                            {markets.map((value, index) => {


                                return <><Tab.Pane eventKey={value} key={index} >
                                    <div>
                                        <Tabs
                                            defaultActiveKey="top-gainers"
                                            id="uncontrolled-tab-example"
                                            className="d-flex gap-2 button-tabs"
                                        >
                                            {/* every gainers tab */}
                                            <Tab eventKey="top-gainers" title="Top Gainers">
                                                <div>
                                                    <Card className="border-0 p-0 mb-3 bg-transparent">
                                                        <Card.Body className="px-3 py-0">
                                                            <div className="d-flex justify-content-between align-items-end">
                                                                <div><span>NAME</span></div>
                                                                <div className="text-center"><span>VALUE</span></div>
                                                                <div><span>CHG (%)</span></div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                    <div className="market-pannel-status">

                                                        {Object.keys(gainers[value]).map((key) => {
                                                            let instrument = gainers[value][key];
                                                            return <>
                                                                <TopGainer instrument={instrument} key={instrument.token} sheetChangingHandler={sheetChangingHandler} />
                                                            </>
                                                        })}
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="top-loosers" title="Top Loosers">
                                                <div>
                                                    <Card className="border-0 p-0 mb-3 bg-transparent">
                                                        <Card.Body className="px-3 py-0">
                                                            <div className="d-flex justify-content-between align-items-end">
                                                                <div><span>NAME</span></div>
                                                                <div className="text-center"><span>VALUE</span></div>
                                                                <div><span>CHG (%)</span></div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                    <div className="market-pannel-status">
                                                        {Object.keys(loosers[value]).map((key) => {
                                                            let instrument = loosers[value][key];
                                                            return <>
                                                                <TopLooser instrument={instrument} key={instrument.token} sheetChangingHandler={sheetChangingHandler} />
                                                            </>
                                                        })}
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </Tab.Pane></>
                            })}

                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            {/* bottom bar */}
            <Footer />
        </div>

    </>
}